<template lang="pug">
  AppButtonWithIcon(
    type="other"
    v-bind="$props"
    v-on="$listeners"
  )
</template>

<script>
  export default {
    props: {
      title: String,
      icon: String,
      disabled: Boolean,
      useAppIcon: Boolean,
      iconBefore: { type: Boolean, default: true }
    },

    components: {
      AppButtonWithIcon: () => import("@/components/elements/AppButton/WithIcon")
    }
  }
</script>

<style lang="sass" scoped>
  ::v-deep
    svg
      height: 15px
      margin-right: 5px
      width: 15px
</style>
